import React, { useState } from 'react'
import BackgroundImage from './BackgroundDark.jpg'
import Logo from './Logo.png'
import axios from 'axios'
import { Grid, Paper, Box, Typography, Button, TextField, Stack } from '@mui/material'
import { styled } from '@mui/system'
import validator from 'validator'
import { useEnvironmentVariables } from './Services/useEnvironmentVaribles'
import CircularProgress from '@mui/material/CircularProgress'
import { green } from '@mui/material/colors'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertProps } from '@mui/material/Alert'

const LoginBox = styled('div')`
  background-image: url(${BackgroundImage});
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #464646;
  width: 100vw;
  height: 100vh;
`

const StyledBox = styled(Box)({
  position: 'relative',
  top: '15%',
})

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  padding: theme.spacing(1),
  textAlign: 'center',
  borderRadius: '8px',
}))

const LogoImage = styled('img')({
  width: '40%',
})

const HeaderText = styled(Typography)({
  color: '#FFFFFF',
  fontSize: '1.2em',
  paddingTop: '10px',
})

const Text = styled(Typography)({
  color: '#FFFFFF',
  paddingBottom: '5px',
})

const TextInput = styled(TextField)({
  backgroundColor: '#FFFFFF',
  color: '#000000',
  width: '60%',
  left: '20%',
})

const StyledButton = styled(Button)({
  width: '50%',
  left: '25%',
  '&:disabled': {
    backgroundColor: 'rgb(17,80,142)!important',
  },
})

type QueryParams = {
  deviceMac: string
  apMac: string
}

type InputValues = {
  clientMAC: string
  aPMac: string
  email: string
  phoneNumber: string
  siteId: string
  siteName: string
  siteFqdn: string
  siteCredentialsReference: string
}

const App = () => {
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [validEmail, setValidEmail] = useState(true)
  const [validPhone, setValidPhone] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  })

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setIsError(false)
  }

  const params = new URLSearchParams(window.location.search)
  const envVars = useEnvironmentVariables()
  const getQueryParams = (): QueryParams => {
    const deviceMac = new URLSearchParams(params).get('id')
    const apMac = new URLSearchParams(params).get('ap')

    return { deviceMac, apMac }
  }

  const validatePhone = (phone: string): boolean => {
    const isValid = validator.isMobilePhone(phone)
    setValidPhone(isValid)
    return isValid
  }

  const validateEmail = (email: string): boolean => {
    if (!email) {
      setValidEmail(true)
      return true
    }
    const isValid = validator.isEmail(email)
    setValidEmail(isValid)
    return isValid
  }

  const onClick = () => {
    const unifiInfo = getQueryParams()
    if (!validateEmail(email) || !validatePhone(phone)) {
      return
    }

    setIsLoading(true)
    setIsError(false)

    const inputs: InputValues = {
      clientMAC: unifiInfo.deviceMac,
      aPMac: unifiInfo.apMac,
      email,
      phoneNumber: phone,
      siteId: envVars.siteId,
      siteName: window.location.pathname.split('/')[3],
      siteFqdn: envVars.siteFqdn,
      siteCredentialsReference: envVars.credReference,
    }
    axios
      .post(envVars.apiUri + '/authorize', inputs)
      .then(response => window.location.replace('https://bing.com'))
      .catch(error => {
        console.log('Could Not Auth: ' + error)
        setIsLoading(false)
        setIsError(true)
      })
  }

  const isDeviceMacPresent = new URLSearchParams(params).has('id')
  const isApMacPresent = new URLSearchParams(params).has('ap')

  if (!isApMacPresent || !isDeviceMacPresent) {
    window.location.replace('https://bing.com')
  }

  return (
    <LoginBox>
      <StyledBox sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid item xs md={3}></Grid>
          <Grid item xs={10} md={6}>
            <Item>
              <LogoImage src={Logo}></LogoImage>
              <Stack spacing={2}>
                <HeaderText>CaptiveFi Test</HeaderText>

                <Text>Please provide your phone number and optional email address to access the WiFi.</Text>

                <TextInput
                  label="Phone Number"
                  variant="filled"
                  value={phone}
                  error={!validPhone}
                  onChange={e => setPhone(e.target.value)}
                  onBlur={e => validatePhone(e.target.value)}
                />
                <TextInput
                  label="Email Address"
                  variant="filled"
                  value={email}
                  error={!validEmail}
                  onChange={e => setEmail(e.target.value)}
                  onBlur={e => validateEmail(e.target.value)}
                />

                <StyledButton variant="contained" size="large" disabled={isLoading} onClick={() => onClick()}>
                  {isLoading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: green[500],
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                  )}
                  Login
                </StyledButton>
              </Stack>
            </Item>
          </Grid>
          <Grid item xs md={3}></Grid>
        </Grid>
      </StyledBox>

      {isError && (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={isError}
          onClose={handleClose}
          key={'bottom' + 'center'}
          autoHideDuration={6000}
        >
          <Alert severity="error" onClose={handleClose} sx={{ width: '100%' }}>
            Something went wrong. Please try again.
          </Alert>
        </Snackbar>
      )}
    </LoginBox>
  )
}

export default App
